/* eslint-disable no-unneeded-ternary */
let apiEndpoint = ''
let commonApi = ''
let downloadErrorFilePath = ''
let socketApi = ''
let chatApi = ''
let munimApiEndpoint = ''
let munimApp = ''
const bucket_url = 'https://cdn.themunim.com/gst/'
const exportFileApi = 'https://munim.blr1.digitaloceanspaces.com/'
let imageFilePath = ''
let downloadSampleFilePath = ''
let product = {
    gst: ''
}
let downloadTemplateSampleFilePath = ''
const hostname = window.location.hostname
const isLive = window.location.hostname !== 'localhost'
if (hostname === 'gst.themunim.com') {
    apiEndpoint = 'https://gstapi.themunim.com/api'
    munimApiEndpoint = 'https://api.themunim.com/api'
    commonApi = 'https://gstapi.themunim.com/'
    socketApi = 'https://gstapi.themunim.com/'
    downloadErrorFilePath = `${bucket_url}live/`
    downloadSampleFilePath = `${bucket_url}live/assets/static/`
    downloadTemplateSampleFilePath = `${bucket_url}live/import_data/import_samples/`
    imageFilePath = `${bucket_url}live/assets/images/`
    chatApi = 'https://apps-bucket.nyc3.digitaloceanspaces.com/'
    munimApp = 'https://app.themunim.com'
    product = {
        gst: 'https://gst.themunim.com'
    }
} else if (hostname === 'devgst.themunim.com') {
    apiEndpoint = 'https://devgstapi.themunim.com/api'
    munimApiEndpoint = 'https://devapi.themunim.com/api'
    commonApi = 'https://devgstapi.themunim.com/'
    socketApi = 'https://devgstapi.themunim.com/'
    downloadErrorFilePath = `${bucket_url}dev/`
    downloadSampleFilePath = `${bucket_url}dev/assets/static/`
    downloadTemplateSampleFilePath = `${bucket_url}dev/import_data/import_samples/`
    imageFilePath = `${bucket_url}dev/assets/images/`
    chatApi = 'https://apps-bucket.nyc3.digitaloceanspaces.com/'
    munimApp = 'https://dev.themunim.com'
    product = {
        gst: 'https://devgst.themunim.com'
    }
} else if (hostname === 'stagegst.themunim.com') {
    apiEndpoint = 'https://stagegstapi.themunim.com/api'
    munimApiEndpoint = 'https://stageapi.themunim.com/api'
    commonApi = 'https://stagegstapi.themunim.com/'
    socketApi = 'https://stagegstapi.themunim.com/'
    downloadErrorFilePath = `${bucket_url}stage/`
    downloadSampleFilePath = `${bucket_url}dev/assets/static/`
    downloadTemplateSampleFilePath = `${bucket_url}dev/import_data/import_samples/`
    imageFilePath = `${bucket_url}dev/assets/images/`
    chatApi = 'https://apps-bucket.nyc3.digitaloceanspaces.com/'
    munimApp = 'https://stage.themunim.com'
    product = {
        gst: 'https://stagegst.themunim.com'
    }
} else if (hostname === 'localhost') {
    apiEndpoint = 'http://localhost:9292/api'
    munimApiEndpoint = 'https://stageapi.themunim.com/api'
    commonApi = 'http://localhost:9292/'
    downloadErrorFilePath = `${bucket_url}local/`
    downloadSampleFilePath = `${bucket_url}local/assets/static/`
    imageFilePath = `${bucket_url}local/assets/images/`
    chatApi = 'https://apps-bucket.nyc3.digitaloceanspaces.com/'
    munimApp = 'http://localhost:3000'
    product = {
        gst: 'http://localhost:4242'
    }
}
module.exports = {
    apiEndpoint,
    commonApi,
    munimApiEndpoint,
    downloadErrorFilePath,
    socketApi,
    chatApi,
    bucket_url,
    exportFileApi,
    imageFilePath,
    downloadSampleFilePath,
    isLive,
    product,
    munimApp,
    downloadTemplateSampleFilePath
}
