import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    gstr9AllData: {},
    isEdit: false,
    successFilingId: ''
}

export const gstr9bDataSlice = createSlice({
    name: 'headerAction',
    initialState,
    reducers: {
        setgstr9DataList: (state, action) => {
            state.outward = action.payload
        },
        setIsEdit: (state, action) => {
            state.isEdit = action.payload
        },
        setSuccessFilingId: (state, action) => {
            state.successFilingId = action.payload
        }
    }
})

// Action creators are generated for each case reducer function
export const { setgstr9DataList, setIsEdit, setSuccessFilingId } = gstr9bDataSlice.actions

export default gstr9bDataSlice.reducer