import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    billingData: {}

}

export const billingDataSlice = createSlice({
    name: 'headerAction',
    initialState,
    reducers: {
        setBillingData: (state, action) => {
            state.billingData = action.payload
        }

    }
})

// Action creators are generated for each case reducer function
export const { setBillingData } = billingDataSlice.actions

export default billingDataSlice.reducer