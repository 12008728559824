const CommonApiEndPoint = {
    reset_password: '/reset-password',
    forgot_password: '/forgot-password',
    log_in: '/log-in',
    sign_up: '/sign-up',
    change_password: '/change-password',
    update_profile: '/update-profile',
    verify_user_otp: '/verify-user-otp',
    verify_password: '/verify-password',
    create_filter: '/create-filter',
    update_filter: '/update-filter',
    create_company: '/create-company',
    update_company: '/update-company',
    update_company_status: '/update-company-status',
    activity_log_list: '/activity-log-list',
    get_user_activity: '/get-user-activity',
    create_activity_log: '/create-activity-log',
    change_email_mobile: '/change-email-mobile',
    set_invoice_status: '/set-invoice-status',
    next_year_stock_transfer: '/next-year-stock-transfer',
    cancel_einvoice: '/canceled-einvoice',
    update_email_preference: '/update-email-preference',
    create_email_preference: '/create-email-preference',
    cancel_eway_bill: '/canceled-eway-bill',
    import_gstr1: '/import-Gstr1',
    import_gstr9: '/import-gstr9-hsn',
    get_import_progress_status: '/get-import-progress-status',
    get_all_notification_list: '/get-all-notification-list',
    get_notification_list: '/get-notification-list',
    remove_notification: '/remove-notification',
    get_return_period_list: '/get-return-period-list',
    get_year_period_list: '/get-year-period-list',
    get_company_user: '/get-company-all-user',
    get_all_company_list: '/get-all-company-list',
    get_filter_detail: '/get-filter-details',
    next_year_transfer: '/next-year-transfer',
    get_opening_data: '/get-opening-data',
    get_bank_list: '/get-bank-list',
    get_customer_list: '/get-customer-list',
    get_states: '/get-states',
    get_cities: '/get-cities',
    get_gst_info: '/get-gst-info',
    get_credit_debit_note_data: '/get-credit-debit-note-data',
    get_credit_debit_note_data_iff: '/get-credit-debit-note-data-iff',
    get_invoice_data: '/get-invoice-data',
    get_invoice_data_iff: '/get-invoice-data-iff',
    get_gstr1_b2cs_data: '/get-gstr1-b2cs-data',
    get_gstr1_export_data: '/get-gstr1-exp-data',
    get_invoice_summary: '/get-invoice-summary',
    get_gst_portal_data: '/get-gst-portal-data',
    upload_gstr1: '/upload-gstr1',
    get_invoice_summary_iff: '/get-invoice-summary-iff',
    get_advance_receipt_data: '/get-advance-receipt-data',
    get_transporter_list: '/get-transporter-list',
    company_list: '/company-list',
    company_details: '/company-details',
    gstr1_filing_status: '/gstr1-filing-status',
    get_gstr1_summary: '/get-gstr1-summary',
    set_gstr1_filing_data: '/set-gstr1-filing-data',
    get_uploaded_gst_data: '/get-uploaded-gst-data',
    get_uploaded_history: '/get-uploaded-history',
    get_import_log_list: '/get-import-log-list',
    get_return_history: '/get-return-history',
    delete_gst_data: '/delete-gst-data',
    get_all_customer_list: '/get-all-customer-list',
    get_all_gst_list: '/get-all-gst-list',
    gstr1_filling_status: '/get-gstr1-filing-status',
    get_dashboard_analitics_data: '/get-dash-analytic-data',
    get_profile: '/get-gst-utility-user-details',
    import_bulk_company: '/import-bulk-company',
    get_upload_via_otp_req: '/upload-via-otp-req',
    get_upload_via_otp_auth: '/upload-via-otp-auth',
    add_user_rating: '/add-user-rating',
    get_latest_news: '/get-latest-news',
    edit_invoice_data: '/edit-invoice-data',
    get_gstr1_sheet_data: '/get-gstr1-sheet-data',
    renew_token: '/renew-token',
    return_evc_file: '/return-evc-file',
    return_gstr9_evc_file: '/return-gstr9-evc-file',
    update_import_progress: '/update-import-progress',
    get_authorised_pan: '/get-authorised_pan',
    set_authorised_pan: '/set-authorised_pan',
    generate_otp: '/generate-otp',
    get_import_summary: '/get-import-summary',
    get_success_sheet_data: '/get-success-sheet-data',
    get_error_json: '/get-error-json',
    edit_error_json: '/edit-error-json',
    verify_user_request: '/verify-user-request',
    munim_connection_req: '/munim-connection-req',
    check_connection: '/check-connection',
    update_connection_status: '/update-connection-status',
    update_request_status: '/update-request-status',
    get_gstr1: '/get-gstr1',
    get_gstr_iff: '/get-gstr-iff',
    save_template_data: '/save-template-data',
    get_govt_notification_data: '/get-govt-notification-data',
    feature_request_comment: '/feature-request-comment',
    save_feature_request_upvote: '/save-feature-request-upvote',
    get_comment_list: '/get-comment-list',
    create_feature_req: '/create-feature-request',
    get_feature_request: '/get-feature-request',
    get_feature_request_details: '/get-feature-request-details',
    get_feature_request_pipeline: '/get-feature-request-pipeline',
    get_feature_request_in_development: '/get-feature-request-in-developement',
    get_feature_request_done: '/get-feature-request-done',
    get_not_uploaded_error_data: '/get-not-uploaded-error-data',
    incomplete_user_profile_status: '/incomplete-user-profile-status',
    update_company_frequency: '/update-company-frequency',
    check_company_user: '/check-company-exist',
    import_purchase_data: '/import-pr-data',
    get_invoice_data_gstr2: '/get-invoice-data-gstr2',
    get_credit_debit_note_data_gstr2: '/get-credit-debit-note-data-gstr2',
    get_advance_receipt_data_gstr2: '/get-advance-receipt-data-gstr2',
    get_impg_data_gstr2: '/get-impg-data-gstr2',
    get_imps_data_gstr2: '/get-imps-data-gstr2',
    get_itcr_data_gstr2: '/get-itcr-data-gstr2',
    get_gstr2_sheet_data: '/get-gstr2-sheet-data',
    edit_cmp_data: '/edit-cmp-data',
    get_cmp_data: '/get-cmp-data',
    delete_cmp_data: '/delete-cmp-data',
    get_reg_type_history: '/get-reg-type-history',
    get_transaction_id: '/get-transaction-id',
    edit_gstr2_Data: '/edit-pr-data',
    generate_login_otp: '/generate-login-otp',
    get_to_be_upload_data: '/get-to-be-upload-data',
    get_section_summary: '/get-section-summary',
    get_password_list: '/get-password-list',
    set_pass_repository: '/set-pass-repository',
    get_last_used_portal_pwd: '/get-last-used-portal-pwd',
    get_pr_invoice_data: '/get-pr-invoice-data',
    get_pr_credit_debit_note_data: '/get-pr-credit-debit-note-data',
    get_pr_advance_receipt_data: '/get-pr-advance-receipt-data',
    get_pr_imps_data: '/get-pr-imps-data',
    get_pr_impg_data: '/get-pr-impg-data',
    get_pr_atadj_data: '/get-pr-atadj-data',
    get_pr_exemp_data: '/get-pr-exemp-data',
    get_pr_itcr_data: '/get-pr-itcr-data',
    get_pr_hsnsum_data: '/get-pr-hsnsum-data',
    get_pr_success_sheet_data: '/get-pr-success-sheet-data',
    get_gstr2b_invoice_data: '/get-gstr2b-invoice-data',
    get_gstr2b_note_data: '/get-gstr2b-note-data',
    get_gstr2b_eco_data: '/get-gstr2b-eco-data',
    get_gstr2b_isd_data: '/get-gstr2b-isd-data',
    get_gstr2b_impg_data: '/get-gstr2b-impg-data',
    get_g2b_customer_data: '/get-g2b-customer-data',
    get_g2b_gstin_data: '/get-g2b-gstin-data',
    import_g2b: '/import-g2b',
    getg2b_rec_supplierwise_data: '/get-g2b-rec-supplierwise-data',
    get_g2b_rec_documentwise_data: '/get-g2b-rec-documentwise-data',
    get_maching_summary: '/get-maching-summary',
    generate_matching_report: '/generate-matching-report',
    get_g2b_rec_summary: '/get-g2b-rec-summary',
    get_g2b_months: '/get-months',
    get_supplier_gst_data: '/get-supplier-gst-data',
    get_supplier_name_data: '/get-supplier-name-data',
    get_all_pr_customer_list: '/get-all-pr-customer-list',
    get_all_pr_gst_list: '/get-all-pr-gst-list',
    update_remarks: '/update-remarks',
    delete_g2b_data: '/delete-all-g2b-data',
    update_matching_data_status: '/update-matching-data-status',
    download_g2b_rec_report: '/download-g2b-rec-report',
    get_tds_tcs_total_summary: '/get-tds-tcs-total-summary',
    get_tds_tcs_invoice_list: '/get-tds-tcs-invoice-list',
    update_tcs_tds_invoice_status: '/update-tds-tcs-invoice-status',
    get_all_tds_tcs_customer_list: '/get-all-tds-tcs-customer-list',
    get_all_tds_tcs_gst_list: '/get-all-tds-tcs-gst-list',
    get_all_tds_tcs_period_list: '/get-all-tds-tcs-period-list',
    validate_tds_tcs_upload_data: '/validate-tds-tcs-upload-data',
    get_g1_filed_credit_debit_data: '/get-g1-filed-credit-debit-data',
    get_g1_filed_sheet_data: '/get-g1-filed-sheet-data',
    get_g1_filed_invoice_data: '/get-g1-filed-invoice-data',
    get_g1_filed_customer_list: '/get-g1-filed-customer-list',
    get_g1_filed_gst_list: '/get-g1-filed-gst-list',
    get_cash_ledger_list: '/get-cash-ledger-list',
    get_cash_ledger_detail_list: '/get-cash-ledger-detail-list',
    get_credit_ledger_list: '/get-credit-ledger-list',
    download_e_ledger_report: '/download-e-ledger-report',
    get_last_ledger_download_date: '/get-last-ledger-download-date',
    get_gstr3b_data: '/get-gstr3b-data',
    get_gstr3b_table_summary: '/gstr3b-table-summary',
    get_last_ledger_balance: '/get-last-ledger-balance',
    save_gstr3b_data: '/save-gstr3b-data',
    get_tds_tcs_upload_summary: '/get-tds-tcs-upload-summary',
    get_tds_tcs_months: '/get-tds-tcs-months',
    get_tds_tcs_summary: '/get-tds-tcs-summary',
    download_g2b_data_report: '/download-g2b-data-report',
    download_tds_tcs_report: '/download-tds-tcs-report',
    get_gst3b_compare_summary: '/get-gst3b-compare-summary',
    set_challan_no: '/set-challan-no',
    create_error_log: '/create-error-log',
    get_tax_payment_data: '/get-tax-payment-data',
    set_freq_used_section: '/set-freq-used-section',
    download_g1_report: '/download-g1-report',
    reset_populate_data: '/reset-populate-data',
    get_gstr9_data: '/get-gstr9-data',
    current_plan: '/check-current-plan',
    continue_trial: '/continue-trial',
    get_user_plan_list: '/get-user-plan-list',
    check_payment_method: '/check-payment-method',
    continue_payment: '/continue-payment',
    get_customer_address_list: '/get-customer-address-list',
    get_payment_detail: '/get-payment-detail',
    subscription_print: '/subscription-print',
    subscription_print: '/subscription-print',
    set_default_billing_address: '/set-default-billing-address',
    update_customer_address: '/update-customer-address',
    create_customer_address: '/create-customer-address',
    delete_customer_address: '/delete-customer-address',
    get_subscription_list: '/get-subscription-list',
    update_organization: '/update-organization',
    get_organization_detail: '/get-organization-detail',
    sent_invitations: '/sent-invitations',
    get_collaboration_list: '/get-collaboration-list',
    edit_up_setting: '/setting/user-permission/edit',
    setting_billing_plan: '/setting/billing-plan',
    edit_accept_up_setting: '/setting/user-permission/edit-accept',
    decline_invitation: '/decline-invitation',
    receive_invitations: '/receive-invitations',
    accept_invitation: '/accept-invitation',
    delete_invitation: '/delete-invitations',
    company_user_permission: '/company-user-permission',
    update_module_list: '/update-module-list',
    get_organization_list: '/get-organization-list',
    update_favorite_org: '/update-favorite-org',
    update_last_selected_org: '/update-last-selected-org',
    verify_invitation: '/verify-invitation',
    verify_discount: '/verify-discount',
    get_gstr9c_data: '/get-gstr9c-data',
    gstr9_table_summary: '/gstr9-table-summary',
    save_gstr9_data: '/save-gstr9-data',
    save_gstr9c_data: '/save-gstr9c-data',
    get_gstr9_hsn_data: '/get-gstr9-hsn-data',
    edit_gstr9_hsn_data: '/edit-gstr9-hsn-data',
    reset_gstr9_data: '/reset-gstr9-data',
    save_gstr9c_basic_info: '/save-gstr9c-basic-info',
    get_gstr9c_basic_data: '/get-gstr9c-basic-data',
    reset_gstr9c_data: '/reset-gstr9c-data',
    upload_gstr9c_document: '/upload-gstr9c-document',
    get_gstr9c_uploaded_file: '/get-gstr9c-uploaded-file',
    get_gstr9_computation_data: '/get-gstr9-computation-data',
    fetch_gstr9_data: '/fetch-gstr9-data'
}
export default CommonApiEndPoint
